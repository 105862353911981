.flex-container {
  display: flex;
}

.flex-start {
  @extend .flex-container;
  justify-content: flex-start;
}

.flex-end {
  @extend .flex-container;
  justify-content: flex-end;
}

.flex-space-between {
  @extend .flex-container;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  @extend .flex-container;
  justify-content: center;
  align-items: center;
}

.flex-column {
  @extend .flex-container;
  flex-direction: column;
  float: left;
}

.flex-row {
  @extend .flex-container;
  flex-direction: row;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}
