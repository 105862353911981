@import "./palette";
@import "../partials/variables";
@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";

.alert {
  width: 100%;
  padding: 1rem;
  color: $white;
  border-radius: 3px;
  border: 0;

  &-danger {
    background: $blood;
    box-shadow: 0 2rem 2rem -1.5rem rgba(255, 0, 0, 0.5);
  }

  &-success {
    background: $green;
    box-shadow: 0 2rem 9rem -2rem rgba(0, 255, 0, 0.6);
  }

  &-info {
    background: $black;
    box-shadow: 0 2rem 9rem -2rem rgba(0, 0, 0, 0.4);
  }

  &-warning {
    background: $orange;
    box-shadow: 0 2rem 9rem -2rem rgba(0, 0, 0, 0.4);
  }

  &--sm {
    font-size: 0.8rem;
    padding: 0.6rem;
  }
}
