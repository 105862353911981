@import "../../../../../styles/partials/variables";
@import "../../../../../styles/redesign/palette";

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  border-radius: 50%;
  border: 2px solid rgba(var(--gray));
  border-top-color: rgba(var(--blue));
  animation: spinner 0.6s linear infinite;
  margin: 0.2rem;
}

.pseudo-spinner:after {
  @extend .spinner;
  content: "";
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 0.8rem;
  right: 0.5rem;
  z-index: $spinner-z-index;
}

.icon-spinner:before {
  @extend .spinner;
  content: "";
  display: inline-block;
  border-width: 1px;
  width: 0.975rem;
  height: 0.975rem;
  margin: 0 0 -0.0625rem 0;
}

.pseudo-spinner-large:after {
  @extend .spinner;
  content: "";
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 2.5rem;
  right: 1rem;
  z-index: $spinner-z-index;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $dark-grey;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
