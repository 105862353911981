@import "palette";
@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";

.full-screen-modal {
  width: 95%;
  height: 93%;

  .modal-content {
    height: 100%;
    overflow-y: auto;
  }
}

.off-background-modal {
  background-color: $off-white;

  .modal-content {
    background-color: $off-white;
    box-shadow: none;
  }
}

.modal-backdrop {
  opacity: 0.5;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 6px !important;
}

.mdc-dialog__title::before {
  content: none !important;
}

.modal-content {
  border-radius: 6px;
}

.modal-backdrop.in,
.modal-backdrop.show {
  z-index: 99998 !important;
}

.modal.in,
.modal.show {
  z-index: 99999 !important;
  opacity: 1;
}

.modal-backdrop.fade.show {
  opacity: 0.5;
}

.modal.fade.show .modal-dialog {
  transform: translate(0);
}
