@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";

.q-modal-overlay {
  background: rgba(0, 0, 0, 0.32);

  & + .cdk-global-overlay-wrapper {
    @media (max-width: $screen-sm) {
      align-items: normal !important;
    }

    .cdk-overlay-pane {
      max-width: 100% !important;
    }
  }
}

.mat-dialog-container {
  height: auto;
  border-radius: 6px !important;

  @media (max-width: $screen-sm) {
    border-radius: 0 !important;
  }
}
