@import "./palette";

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
  .mdc-evolution-chip__text-label,
.mat-mdc-chip-action-label {
  color: inherit !important;
}

.mat-mdc-form-field-infix {
  min-height: 43px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 0.5rem 0;
}

.mat-mdc-select,
.mdc-menu-surface.mat-mdc-select-panel,
.mat-mdc-option.mdc-list-item {
  font-size: 1rem;
}

.mdc-menu-surface.mat-mdc-select-panel {
  //min-height: 275px !important;
  max-height: min(310px, 36vh) !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled.mdc-text-field--no-label {
  padding: 0;
}

.mdc-text-field--filled {
  font-size: 1rem;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: none;
  padding: 0;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
    .mdc-text-field--textarea
  )
  .mat-mdc-form-field-infix {
  padding-bottom: 0;
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  background: none;
  opacity: 0 !important;
}

.cdk-overlay-pane:not(.mat-mdc-select-panel-above)
  .mdc-menu-surface.mat-mdc-select-panel {
  min-width: 270px;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 1rem !important;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  padding-top: 24px;
  padding-bottom: 0;
}

.dropdown-menu-right {
  inset: 100% 0 auto auto !important;
}

.mat-mdc-checkbox label {
  margin-bottom: 0;
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: $quedro-blue !important;
}

.dropdown-item {
  display: block;
  padding-left: 0.5rem;
  width: 100%;
  text-align: left;

  &:hover {
    background-color: rgb(var(--blue));
    color: rgb(var(--white));
  }
}

.popover-container {
  display: inline-block;
}

.popover {
  border: 1px solid rgba(46, 91, 255, 0.08);
  border-radius: 6px;
  box-shadow: 0 10px 20px rgba(46, 91, 255, 0.07);
  background: rgb(var(--white)) !important;
  color: rgb(var(--primary)) !important;
  font-size: 15px;
  max-width: 350px !important;

  .popover-content {
    padding: 9px 10px;
  }
}

.popover--arrow-left ~ .popover {
  left: -8px !important;

  & > .arrow {
    border-left-color: rgba(46, 91, 255, 0.12);
    right: -22px;
    top: 25% !important;

    &::after {
      border-left-color: rgb(var(--white)) !important;
      right: -0.69rem;
      top: -0.75rem !important;
    }
  }
}

.popover--arrow-right ~ .popover {
  left: 8px !important;

  & > .arrow {
    border-right-color: rgba(46, 91, 255, 0.12);
    left: -22px;
    top: 25% !important;

    &::after {
      border-right-color: rgb(var(--white)) !important;
      left: -0.69rem;
      top: -0.75rem !important;
    }
  }
}
