@import "../partials/variables";
@import "../redesign/palette";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.center {
  text-align: center;
}

.overflow-visible {
  overflow: visible !important;
}

.relative {
  position: relative;
}

.contact-icon {
  color: $contacts;

  &--person {
    color: $contacts;
  }

  &--company {
    color: $company;
  }

  &--estate {
    color: $stars;
  }
}

.property-icon {
  color: rgba(var(--blue));

  &--condominium {
    color: rgba(var(--blue));
  }

  &--rental {
    color: rgba(var(--orange));
  }

  &--project {
    color: rgba(var(--purple));
  }
}

.clickable:hover,
.q-table-row:hover {
  .contact-icon {
    &--person,
    &--company,
    &--estate {
      color: inherit;
    }
  }

  .property-icon {
    &--project,
    &--condominium,
    &--rental {
      color: inherit;
    }
  }
}
