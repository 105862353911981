.fade-up {
  opacity: 0;
  animation: fade-up;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  z-index: 1;
}

.fade-down {
  opacity: 0;
  animation: fade-down;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  z-index: 1;
}

.fade-left {
  opacity: 0;
  animation: fade-left;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
  z-index: 1;
}

.fade-right {
  opacity: 0;
  animation: fade-right;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  z-index: 1;
}

.fade-in-1 {
  opacity: 0;
  animation: fade-in;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  z-index: 1;
}

.fade-in-2 {
  opacity: 0;
  animation: fade-in;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  z-index: 1;
}

.fade-in-3 {
  opacity: 0;
  animation: fade-in;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
  z-index: 1;
}

.fade-in-4 {
  opacity: 0;
  animation: fade-in;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 0.9s;
  animation-fill-mode: forwards;
  z-index: 1;
}

.fade-in-5 {
  opacity: 0;
  animation: fade-in;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 1.2s;
  animation-fill-mode: forwards;
  z-index: 1;
}

.fade-in-6 {
  opacity: 0;
  animation: fade-in;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
  z-index: 1;
}

.fade-in-7 {
  opacity: 0;
  animation: fade-in;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 1.8s;
  animation-fill-mode: forwards;
  z-index: 1;
}

.loading-pulse {
  animation: loading-pulse;
  animation-iteration-count: infinite;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate-reverse;
  transition: all 0.3s ease-in-out;
  transform: scale(0.98);
}

@keyframes loading-pulse {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.3;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-up {
  from {
    transform: translateY(2%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-down {
  from {
    transform: translateY(-2%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  from {
    transform: translateX(-2%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-left {
  from {
    transform: translateX(2%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
