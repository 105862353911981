@import "./buttons";
@import "./date-picker";
@import "./tooltip";

.q-filters-container {
  padding: 0 16px 16px;
  border-radius: 6px;
  background-color: rgb(var(--white));
  box-shadow: 0 4px 16px rgba(0, 108, 217, 0.15);
}
