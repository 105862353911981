@import "./palette";
@import "../partials/variables";
@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";

input:not([type="checkbox"]),
textarea,
select {
  height: auto;
  background: #ffffff;
  // UNCOMMENT BORDER FOR OLD STYLE
  border: 1.5px solid $dark-grey;
  border-radius: 5px;

  font-size: 1rem;
  padding: 0.85rem;
  transition: all 0.3s ease-in-out;
  box-shadow: none;
  color: $night;
  width: 100%;

  &:active,
  &:focus,
  &:hover {
    border-color: $black;
    color: $black;
  }
}

.input {
  &--validation {
    border-color: $alert;

    + .icon {
      color: $alert;
    }

    &:active,
    &:focus,
    &:hover + .icon {
      color: $alert;
    }

    &:active,
    &:focus,
    &:hover {
      border-color: $alert;
    }
  }

  &-sm {
    padding: 0.5rem !important;
  }

  &-lg {
    @media (min-width: $screen-xl-min) {
      font-size: 1.5rem !important;
    }
  }

  &--no-border {
    border: 0 !important;
  }
}

//************************************************************************************
//
// Container for inputs and selects with icons. Wrap the input with this class
// and add the class .icon on the icon for consistency.
// e.g.
//  <div class="input-iconfield">
//    <input type="text" placeholder="placeholder" >
//    <i class="icon icon-calendar" aria-hidden="true"></i>
//  </div>
//
//************************************************************************************
.input-iconfield {
  position: relative;

  .icon {
    left: 1rem;
    bottom: 30%;
    font-size: 1.5rem;
    position: absolute;
    transition: all 0.3s ease-in-out;

    //simpleline icons icon-magnifier specific
    &-magnifier {
      font-size: 1.2rem;
      bottom: 1.2rem;
    }

    &--right {
      left: auto;
      right: 1rem;
    }
  }

  &__clear {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: $disabled;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      color: $night;
    }
  }

  label {
    display: block;
  }

  input {
    padding-left: 3rem !important; //creates space for the icon
  }

  select {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAJCAYAAAA/33wPAAAAvklEQVQoFY2QMQqEMBBFv7ERa/EMXkGw11K8QbDXzuN4BHv7QO6ifUgj7v4UAdlVM8Uwf+b9YZJISnlqrfEUZVlinucnBGKaJgghbiHOyLyFKIoCbdvecpyReYvo/Ma2bajrGtbaC58kCdZ1RZ7nl/4/4d5EsO/7nzl7IUtodBexMMagaRrs+06JLMvcNWmaOv2W/C/TMAyD58dxROgSmvxFFMdxoOs6lliWBXEcuzokXRbRoJRyvqqqQvye+QDMDz1D6yuj9wAAAABJRU5ErkJggg==)
      white right center no-repeat;
    appearance: none;
    padding-left: 3rem !important;
    padding-right: 1.5rem !important;
  }

  //modifier for create contact phone number input
  &--flag {
    input {
      padding: 1rem 0 1rem 4rem !important;
    }

    .icon {
      left: 0.2rem;
      bottom: 0.1rem;
    }
  }
}

//************************************************************************************
//
// Container for inputs with checkbox with tag style, wrap the input with this class for consistency.
// e.g.
// <div class="input-checkbox-container">
//  <input type="checkbox">
//  <span>Text example</span>
// </div>
//
//************************************************************************************
.checkbox-tag-group {
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem 0;

  .input-checkbox-container {
    margin-right: 0.25rem;
    display: flex;
    transition: all 0.2s ease-out;

    &:active {
      transform: scale(0.95);
    }

    label {
      border: 2px solid $quedro-blue;
      color: $quedro-blue;
      padding: 0.5rem 1rem;
      border-radius: 20px;
      cursor: pointer;

      &:hover {
        @media (min-width: $screen-sm) {
          color: $white;
          background: $quedro-blue;
        }
      }
    }

    input[type="checkbox"] {
      display: none;

      &:checked + label {
        color: $white;
        background: $quedro-blue;
      }
    }
  }
}

//************************************************************************************
//
// Container for custom styled checkboxes, wrap the input with checkbox-group.
// e.g.
// <label class="checkbox-group">
//  Text example
//  <input type="checkbox">
//  <span class="checkbox-group__checkmark"></span>
// </label>
//
//************************************************************************************
.checkbox-group {
  display: block;
  position: relative;
  padding-left: 2rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  user-select: none;

  &:hover input ~ .checkbox-group__checkmark {
    background-color: #e9e9e9;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkbox-group__checkmark {
      background-color: $quedro-blue;
    }

    &:checked ~ .checkbox-group__checkmark:after {
      display: block;
    }

    &:disabled ~ .checkbox-group__checkmark {
      background-color: $disabled;
      border: none;
      cursor: not-allowed;
    }

    &:disabled ~ .checkbox-group__checkmark:after {
      display: none;
    }
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #c9c9c9;

    &:after {
      left: 39%;
      top: 19%;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      content: "";
      position: absolute;
      display: none;
    }

    &--margin-fix {
      margin-left: 0.3rem;
    }
  }
}

//************************************************************************************
//
// Container for custom styled radio buttons, wrap the input with radio-button-group.
// e.g.
// <label class="radio-button-group">
//  Text example
//  <input type="radio">
//  <span class="radio-button-group__checkmark"></span>
// </label>
//
//************************************************************************************

.radio-button-group {
  display: block;
  position: relative;
  padding-left: 1.75rem;
  cursor: pointer;
  user-select: none;

  &:hover input ~ .radio-button-group__checkmark {
    background-color: #ddd;
  }

  input {
    position: absolute;
    opacity: 0;

    &:checked ~ .radio-button-group__checkmark {
      background-color: $quedro-blue;
    }

    &:checked ~ .radio-button-group__checkmark:after {
      display: block;
    }
  }

  &__checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 1.25rem;
    height: 1.25rem;
    background-color: $white;
    border-radius: 50%;
    border: 0.5px solid #c9c9c9;

    &:after {
      align-self: center;
      content: "";
      display: none;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $off-white;
    }
  }
}

//************************************************************************************
//
// Consent style group.
// Requires variable in typescript file called: checkedInput = false;
//
// e.g.
// <div class="consent">
//   <div class="consent__icon-container">
//     <i class="icon-gdpr"></i>
//   </div>
//   <div class="consent__box" [ngClass]="checkedInput ? 'success-bg' : ''">
//     <label class="checkbox-group">
//       text
//       <input type="checkbox" [checked]="checkedInput" (change)="checkedInput = !checkedInput" formControlName="consent" required>
//       <span class="checkbox-group__checkmark"></span>
//     </label>
//   </div>
// </div>
//
//************************************************************************************

.consent-group {
  color: $white;
  text-align: center;
  width: 100%;
  padding: 1rem;
  border: 2px solid $night;
  border-radius: 10px;
  // Fix for mobile
  margin-bottom: 3rem;

  &__icon-container {
    text-align: center;
    width: 100%;
    padding: 0.5rem 0;
  }

  &__box {
    background: $night;
    padding: 1rem;
    border-radius: 10px;
    overflow: hidden;

    label {
      margin: 0;
      z-index: 0;
      text-align: left;

      input {
        &:checked ~ .checkbox-group__checkmark {
          background-color: #3d474c;
          border: 0;
        }
      }
    }
  }

  .success-bg {
    background: $green;
    transition: all 0.2s ease-out;
  }
}

.seperator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.25rem;
  font-size: 2rem;
}

textarea {
  resize: none;
}

select {
  background: #fff;
  color: $black;

  // UNCOMMENT BORDER FOR OLD STYLE
  border: 1.5px solid $dark-grey;

  height: 3.5rem;
  width: 100%;
  min-width: 7rem;

  &:active,
  &:focus,
  &:hover {
    border-color: $black !important;
  }
}

.select {
  &--medium {
    height: 50px;
  }
}

label {
  font-weight: normal;
  font-style: italic;
}

input[matInput],
textarea[matInput] {
  background-color: transparent;
  border-radius: 0px;
  border: 0;
  box-shadow: none !important;
  padding: 0;
}

input[matInput]:active,
input[matInput]:focus,
input[matInput]:hover,
textarea[matInput]:active,
textarea[matInput]:focus,
textarea[matInput]:hover {
  box-shadow: none !important;
}

.mat-checkbox .mat-checkbox-ripple {
  left: calc(50% - 15px) !important;
  top: calc(50% - 15px) !important;
  height: 30px !important;
  width: 30px !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element {
  background: $quedro-blue;
}

//Remove Chrome yellow color on fields
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:active,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:active,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
  background-color: #fff !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background {
  background-color: $quedro-blue !important;
  border-color: $quedro-blue !important;
}
