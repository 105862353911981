@import "../partials/variables";

.link {
  cursor: pointer;
}

.text-link {
  @extend .link;
  color: $brand-primary;
}
