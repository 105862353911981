@import "./palette";

.panel {
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  $p: &;

  .panel-open & {
    .panel-heading {
      background: $off-white !important;
      border: 0 !important;
      color: $quedro-blue !important;
      cursor: pointer;
      box-shadow: 1px 2px 4px #999;
      &:after {
        transition: all 0.2s ease-out;
        content: "-";
        font-size: 1.5rem;
      }
      &:hover {
        transition: all 0.2s ease-out;
        background: $off-white !important;
        .btn-link {
          color: $quedro-blue !important;
        }
      }
    }
  }

  &-heading {
    position: relative;
    background: none !important;
    border: 2px solid $quedro-blue !important;
    color: $quedro-blue !important;
    border-radius: 5px !important;
    transition: all 0.2s ease-out;
    padding: 0;
    &:hover {
      background: $quedro-blue !important;
      .btn-link {
        color: $white !important;
      }
    }
    &:after {
      content: "+";
      position: absolute;
      right: 10px;
      bottom: 25%;
    }

    .btn-link {
      &:hover,
      &:active,
      &:focus {
        background: none;
      }
      &:hover {
        color: $white;
      }
    }
  }
  &-body {
    transition: all 0.2s ease-out;
    background: $white;
  }
}
