@import "./_variables.scss";
@import "../redesign/palette";
@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "bootstrap-sass/assets/stylesheets/bootstrap/mixins/buttons";

.btn {
  font-weight: 500;
}

.btn-default.active,
.btn-default:active {
  @include button-variant(
    $btn-primary-color,
    $btn-primary-bg,
    $btn-primary-border
  );
}
.btn-enhanced {
  display: block;
  width: 100%;
  background-color: white;
  border: 1px solid $quedro-blue;
  color: $quedro-blue;
  border-radius: 2rem;
  font-size: 0.9rem;
  padding: 0.7rem;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 1rem;

  i {
    margin-right: 0.25rem;
    &:hover {
      color: white;
    }
  }
  &:hover {
    background-color: $quedro-blue;
    color: white;
  }
}
