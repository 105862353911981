@import "bootstrap-sass/assets/stylesheets/bootstrap";

%message {
  text-align: left;
}
[ngxError] {
  margin-bottom: 0 !important;
}

[ngxError],
.validation-message {
  @extend %message;
  color: $brand-danger;
  font-size: 0.875rem;
  display: block;
}

.validation-input,
.validation-input input,
.validation-input select {
  border-color: $brand-danger !important;
  background: #fff3f3 !important;
}

.required:after {
  content: " *";
  color: $brand-danger;
}
