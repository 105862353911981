.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 0;
}

.disable-events {
  pointer-events: none;
}
