@import "./mixins";
@import "./palette";

.redesign-list {
  &--theme-blue {
    @include barChart($ocean-blue-reverted, $sky-blue, $quedro-blue, #009ada);
  }

  &--theme-peach {
    @include barChart($peach, $orange, $orange, $orange);
  }

  &--theme-gray {
    @include barChart(
      linear-gradient(46deg, $dark-grey 0%, $light-grey 100%),
      linear-gradient(69deg, $light-grey 0%, $dark-grey 100%),
      $dark-grey,
      $night
    );
  }
}
