@import "../../styles/redesign/palette";

.cal-month-view {
  font-family: "Roboto", sans-serif;
  margin-bottom: 1rem;

  .cal-day-cell,
  .cal-day-cell:not(:last-child),
  .cal-days .cal-cell-row,
  .cal-days {
    border: 0;
    min-height: 0;
  }

  .cal-day-cell.cal-today,
  .cal-cell-row .cal-cell:hover,
  .cal-cell-row:hover,
  .cal-cell.cal-has-events.cal-open {
    background-color: transparent;
  }

  .cal-week-number {
    color: $dark-blue;
    cursor: default;
    font-size: 12px;
    &.active {
      color: $quedro-blue;
    }
  }
}
