/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@use "@angular/cdk" as cdk;

// Bootstrap
@import "./styles/partials/variables";
@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";

// Angular CDK
@import "@angular/material/prebuilt-themes/indigo-pink.css";

@include cdk.overlay();

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// App
@import "./styles/partials/links";
@import "./styles/partials/validation";
@import "./styles/partials/navs";
@import "./styles/partials/input-groups";
@import "./styles/partials/tabs";
@import "./styles/partials/flex-utils";
@import "./styles/partials/utils";
@import "./styles/partials/buttons";
@import "./styles/partials/table";
@import "./styles/partials/loading";
@import "../src/app/shared/modules/ui-components/spinner/spinner.component";
@import "./styles/animations";
@import "./styles/utils";
@import "./styles/partials/modal";

// Mobile
@import "./styles.mobile";

//Redesign, wrapped inside .login class inside each partial.
@import "styles/redesign-style";

@import "styles/newton";

* {
  outline: none;
}

a:focus {
  text-decoration: none;
}

html {
  font-size: 80% !important;
}

body {
  position: relative;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
button,
.btn {
  font-family: Roboto, sans-serif !important;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.25rem;
}

h5,
.h5 {
  font-size: 1rem;
}

p,
a {
  font-family: "Open Sans", sans-serif !important;
}

textarea {
  padding: 0.8rem 1rem;
}

small {
  color: $font-color-meta;
  font-size: 0.75rem;
}

.muted {
  font-style: italic;
  color: #bbbbbb;
  font-size: 1rem;
}

typeahead-container {
  ul li.active {
    small {
      color: white !important;
    }
  }
}

search-employee {
  typeahead-container {
    position: relative !important;
    top: 0 !important;

    ul.dropdown-menu {
      padding: 0;
      min-width: 100%;
    }

    ul.dropdown-menu > li > a {
      padding: 0;
    }
  }
}

.sticky_list_header {
  background-color: $white;

  tr {
    display: flex;
    justify-content: space-between;
  }

  tr th {
    border-bottom: none !important;
  }
}

img.productnews {
  width: 100% !important;
}

iframe {
  border: none !important;
  width: 100%;
  min-height: 427px;
}

.nopadding {
  padding: 0;
}

.nomargin {
  margin: 0;
}

.data-missing-text {
  font-style: italic;
}

.text-bold {
  font-weight: bold !important;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 99999 !important;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 100000 !important;
}

.cdk-overlay-transparent-backdrop {
  z-index: 99999 !important;
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 100000 !important;
}

// Todo: Replace this with custom material theme
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $quedro-blue;
}

.mat-radio-checked.mat-accent .mat-radio-inner-circle {
  background-color: $quedro-blue;
}

.mat-radio-checked.mat-accent .mat-radio-outer-circle {
  border-color: $quedro-blue !important;
}

.mat-badge-content {
  padding: 0 !important;
}

.mat-chip-list-wrapper {
  display: inline-block !important;
}

.sheet-container {
  border-radius: 10px 10px 0 0;
}

.full-bleed-sheet {
  padding: 0 !important;
}

.blue-status-mat-chip {
  mat-chip {
    background-color: $quedro-blue !important;
  }
}

.gray-status-mat-chip {
  mat-chip {
    background-color: $dark-grey !important;
  }
}

.orange-status-mat-chip {
  mat-chip {
    background-color: $orange !important;
  }
}

.green-status-mat-chip {
  mat-chip {
    background-color: $green !important;
  }
}

/* Apply Mac scrollbar styles in browsers in Windows */
.windows {
  /* Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: white;
  }

  ::-webkit-scrollbar-thumb {
    background: #737373;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* Firefox */
  scrollbar-color: #737373 white;
  scrollbar-width: thin;
}
