@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../../styles/redesign/palette";

$height-lg: 3.3rem;
$height-sm: 2.2rem;

$width-lg: 4rem;
$width-sm: 2.2rem;

.search {
  height: $height-lg;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__bar {
    position: relative;
    width: 100%;
    margin: 0 1rem;
    transition: all 0.6s ease-in-out;
    cursor: pointer;
    @media (min-width: $screen-lg-min) {
      width: 25rem;
    }

    input {
      background: $navy;
      border-radius: 6px !important;
      color: #fff !important;
      padding-left: 4rem;
      font-family: "Roboto", sans-serif;
      letter-spacing: 2px;
      height: $height-lg;
      width: 100%;
      border: 1px solid rgba(var(--lightgray));
      text-overflow: ellipsis;
      @media (max-width: $screen-sm-max) {
        width: 90%;
      }

      &:hover {
        border-color: rgba(var(--white));
      }
    }

    &--black {
      input {
        background: $night !important;
      }
    }

    &--lg {
      width: 100%;
      @media (min-width: $screen-sm-min) {
        width: 70% !important;
      }
    }

    i {
      position: absolute;
      color: rgba(var(--white));
      font-size: 1.5rem;
      z-index: 10;
      bottom: 1rem;
      left: 1.5rem;
    }
    ::placeholder {
      font-size: 1rem;
      letter-spacing: 1px;
      color: rgba(var(--white));
      opacity: 1;
    }
  }

  &__spinner {
    margin-right: 1rem;
    position: absolute;
    top: 0.6rem;
    right: 0;
    z-index: 10;
    @media (max-width: $screen-sm-max) {
      right: 2rem;
    }
    &:after {
      border-top-color: #fff !important;
    }
  }
  @media (max-width: $screen-sm-max) {
    i {
      margin-top: 0.65rem;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .search-spinner {
    margin-right: 0.5rem;
  }

  .search-icon {
    height: $height-sm;
    width: $width-sm;
    font-size: 0.9em;
  }

  input {
    height: $height-sm;
    font-size: 1rem;
  }

  .input-group {
    width: calc(100% - #{$width-sm});
  }
}
