@import "../partials/variables";

.icon {
  &-gdpr {
    display: inline-block;
    background: url("/assets/icons/icon-gdpr.svg") no-repeat center;
    width: 36px;
    height: 35px;
    margin-top: -1.5rem;
    margin-bottom: -0.25rem;
  }
  &-gdpr--white {
    display: inline-block;
    background: url("/assets/icons/icon-gdpr--white.svg") no-repeat center;
    width: 36px;
    height: 35px;
    margin-top: -1.5rem;
    margin-bottom: -0.25rem;
  }

  &-eika {
    display: inline-block;
    background: url("/assets/icons/icon-eika.svg") no-repeat center;
    width: 30px;
    height: 15px;
    margin-top: -1rem;
    margin-bottom: -0.25rem;
  }

  &-external-tips {
    display: inline-block;
    mask: url("/assets/icons/icon-external-tips-inside.svg") no-repeat center;
    width: 100%;
    height: 21px;
    background-color: $brand-primary;
    background-size: contain;
    &:hover {
      background-color: #ffffff;
    }
    a.active & {
      background-color: #ffffff;
    }
    a.btn & {
      background-color: #ffffff;
      width: 1rem;
    }
    .btn & {
      margin-right: 1.25rem;
    }
  }
  &-nps {
    &--orange {
      display: inline-block;
      width: 100%;
      height: 60px;
      margin-top: -1.5rem;
      margin-bottom: -0.25rem;
      background: url("/assets/icons/street-view-orange.svg") no-repeat center;
    }

    &--red {
      display: inline-block;
      width: 100%;
      height: 60px;
      margin-top: -1.5rem;
      margin-bottom: -0.25rem;
      background: url("/assets/icons/street-view-red.svg") no-repeat center;
    }

    &--green {
      display: inline-block;
      width: 100%;
      height: 60px;
      margin-top: -1.5rem;
      margin-bottom: -0.25rem;
      background: url("/assets/icons/street-view-green.svg") no-repeat center;
    }

    &--gray {
      display: inline-block;
      width: 100%;
      height: 60px;
      margin-top: -1.5rem;
      margin-bottom: -0.25rem;
      background: url("/assets/icons/street-view-gray.svg") no-repeat center;
    }

    &--black {
      display: inline-block;
      width: 100%;
      height: 60px;
      margin-top: -1.5rem;
      margin-bottom: -0.25rem;
      background: url("/assets/icons/street-view.svg") no-repeat center;
    }
  }
}
