@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";

@media (max-width: $screen-sm-max) {
  .container.no-padding {
    padding-right: 0;
    padding-left: 0;
  }

  .container.no-padding > [class*="row"] {
    margin-right: 0;
    margin-left: 0;
  }

  .container.no-padding > [class*="row"] > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }

  .container-fluid.no-padding {
    padding-right: 0;
    padding-left: 0;
  }

  .container-fluid.no-padding > [class*="row"] {
    margin-right: 0;
    margin-left: 0;
  }

  .container-fluid.no-padding > [class*="row"] > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }

  .row.no-padding {
    margin-right: 0;
    margin-left: 0;
  }

  .row.no-padding > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }

  .sticky_list_header {
    display: none;
  }
}
