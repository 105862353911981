.q-tooltip {
  background-color: rgb(var(--primary));
  color: rgb(var(--white));
  font-size: 12px;
  border-radius: 3px;
  padding: 1px;
}

.mdc-tooltip__surface {
  background: none !important;
}
