@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "variables";
@import "../redesign/lists";
@import "../redesign/palette";

.td {
  &--bold {
    font-weight: 500 !important;
  }
}

table.table {
  position: relative;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 0;
  box-shadow: 0 10px 15px 1px lightgrey;
  @media screen and (min-width: $screen-md-min) {
    thead {
      @include listHeader;
    }
  }

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000000;
  }

  .padding-left-x {
    padding-left: 1.3rem;
  }

  &--striped {
    tbody {
      td {
        padding: 1.3rem !important;
        border: none !important;
        @media (max-width: $screen-md) {
          text-align: right;
          padding-left: 2rem !important;
          white-space: normal;
        }
      }
      tr:nth-child(odd) {
        background-color: $white;
      }
      tr:nth-child(even) {
        background-color: $off-white;
      }
    }
  }

  &--white-header {
    box-shadow: none !important;
    thead {
      box-shadow: none !important;
      background: $white;
      border: 0;
      tr {
        th {
          padding: 1.5rem 1rem !important;
          font-weight: 500 !important;
          border-bottom: 1px solid $off-white !important;
        }
      }
    }
  }

  & tr.disabled {
    background-color: #ececec;
    color: #b9b9b9;
    cursor: not-allowed;

    &:hover {
      background-color: #ececec;
    }
  }
}

@media screen and (min-width: $screen-md-min) {
  table.table > thead > tr > th,
  table.table > tbody > tr > td {
    padding: 1rem;
    vertical-align: middle;
    font-weight: 300;
  }

  table.table > tbody > tr:hover {
    background: $link-bg-color;
  }
}

@media screen and (max-width: $screen-sm-max) {
  table.table caption {
    font-size: 1.3rem;
  }

  table.table thead {
    overflow: hidden;
    position: absolute;
    width: 1px;
  }

  table.table tr {
    display: block;
    padding: 0.5rem 0 1.5rem 0;
    border-radius: 5px;
    margin: 0 0.5rem 1rem 0.5rem;
  }

  table.table td {
    display: block;
    height: 100%;
    text-align: left;
    margin-left: -2rem;
    padding: 0;
    white-space: nowrap;
    width: 370px;
  }

  table.table td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-align: right;
    margin-right: 1rem;
    height: 19px;
    width: 140px;
    text-overflow: ellipsis;
  }

  table.table td[datalabel="hasBooking"],
  table.table td[datalabel="active"] {
    &:before {
      margin-left: 1.6rem;
    }
  }

  input[type="checkbox"] {
    height: auto;
  }
}
//iphone 4/5/5s
@media (max-width: 320px) {
  table.table td {
    width: 340px;
  }
}
