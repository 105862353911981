:root {
  --white: 255, 255, 255;
  --orange: 255, 200, 0;
  --yellow: 255, 218, 68;
  --green: 0, 240, 156;
  --darkgreen: 0, 185, 156;
  --blue: 1, 127, 255;
  --purple: 149, 99, 254;
  --red: 255, 0, 92;
  --gray: 40, 47, 57;
  --lightgray: 135, 152, 173;
  --primary: 40, 47, 57;
  --secondary: 1, 127, 255;

  --alpha-15: 0.15;
  --alpha-30: 0.3;
  --alpha-45: 0.45;
  --alpha-60: 0.6;
  --alpha-75: 0.75;
  --alpha-90: 0.9;

  --chip-1-bg: rgba(var(--blue), var(--alpha-15));
  --chip-2-bg: rgba(var(--orange), var(--alpha-15));
  --chip-3-bg: rgba(var(--green), var(--alpha-15));
  --chip-4-bg: rgba(var(--gray), var(--alpha-15));
  --chip-5-bg: rgba(var(--red), var(--alpha-15));

  --chip-1-text: rgb(var(--blue));
  --chip-2-text: rgb(var(--orange));
  --chip-3-text: rgb(var(--green));
  --chip-4-text: rgb(var(--gray));
  --chip-5-text: rgb(var(--red));

  --card-1: rgb(var(--purple));
  --card-2: rgb(var(--yellow));
}

//Gradients
$ocean-blue: rgb(var(--blue));
$sky-blue: rgb(var(--blue));
$peach: linear-gradient(46deg, #ff5f6d 0%, #ffc371 100%);
$ninja-orange: linear-gradient(46deg, #ff9f00 0%, #ffcb66 100%);
$blood: linear-gradient(-228deg, #f5515f 0%, #9f041b 100%);
$forest: linear-gradient(to right, #40bf9f, #5380ac);
$grass: linear-gradient(59deg, #28e57c 25%, #70f4ab 100%);

$ocean-blue-reverted: rgba(var(--blue));
$sky-blue-reverted: rgba(var(--blue));

//Gradient hover
$ocean-blue-hover: rgba(var(--blue));
$blood-hover: linear-gradient(-228deg, #9f041b 0%, #f5515f 100%);

//Colors
$green: #28e57c;
$quedro-blue: rgba(var(--blue));
$orange: #ff9f00;
$alert: rgba(var(--red));
$dark-green: #4aa4a3;
$dark-blue: #317db0;

//Greyscale
$black: #1e1f21;
$night: #2a2a2a;
$dark-grey: #666666;
$light-grey: #868686;
$off-white: #f5f8fa;
$white: #fff;
$disabled: #e2e2e2;
$border: #e2e2e2;

//Bluescale
$navy: #222c49;
$dark-blue: #374f77;
