@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "./palette";

@mixin listHeader() {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  background: #eaf0f6;
  position: relative;
  //box-shadow: 1px 1px 4px #aaa;
  color: $navy;
  padding: 1rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  letter-spacing: 0.5px;

  //span in header style override
  span {
    font-family: "Roboto", sans-serif !important;
    font-weight: 400 !important;
  }
}

.list {
  &__header {
    @include listHeader;
    &__text-container {
      padding-right: 6rem;
    }
    &--action {
      @include listHeader;
      position: relative;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      &:hover {
        background: #e1e8ee;
      }
    }
  }

  &__container {
    box-shadow: 0 1.5rem 3rem -1.6rem rgba(0, 0, 0, 0.6);
  }

  &__item {
    display: flex;
    flex-direction: column;
    font-family: "Roboto", sans-serif;
    padding: 1rem;
    background: #ffffff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in;
    cursor: pointer;
    @media (min-width: $screen-md-min) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    @media (min-width: $screen-xl-min) {
      padding: 1.4rem;
    }
    &:hover {
      background: $off-white;
    }

    a {
      text-decoration: none;
    }

    i {
      font-size: 1.5rem;
    }
    &--empty {
      list-style: none;
      color: #6c6f6f;
      text-align: left;
      background: #fff;
      padding: 0.5rem 0;
    }
    &:active {
      transform: scale(0.95);
    }
  }

  &:first-child li,
  &:last-child li {
    border-top: 0 !important;
  }

  .active {
    background: $quedro-blue;
    color: #fff;
  }
}
