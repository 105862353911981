@import "../../styles/redesign/palette";
@import "../../styles/partials/variables";
@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";

$heading: 2rem;
$text: 1rem;

.competitions {
  overflow: hidden;
  .blue {
    background: $sky-blue;
  }

  .peach {
    background: $peach;
  }

  .gray {
    background: linear-gradient(46deg, $dark-grey 0%, $light-grey 100%);
  }

  &__header {
    width: 100%;
    height: 7rem;
    background: $sky-blue;
    color: $white;
    border-radius: 10px;
    position: relative;
    padding: 0;
    margin-top: 1rem;
    transition: all 0.2s ease-in-out;

    @media (max-width: $screen-sm) {
      height: auto;
    }
    @media (min-width: $screen-xl-min) {
      height: 9rem;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: row;
      z-index: 200;
      height: inherit;
      background: url("/assets/img/city.png") repeat-x center bottom;
      width: 100%;
      margin-top: 1rem;
      padding: 0 0.5rem;
      border-radius: 10px;
      @media (max-width: $screen-sm) {
        flex-direction: column;
        height: inherit;
        padding: 1rem 0;
      }
    }

    &__block {
      display: contents;
      @media (min-width: $screen-sm) {
        display: flex;
        align-items: inherit;
        width: 30%;
      }
    }

    &__headline {
      flex: 2;
      font-weight: 400;
      @media (max-width: $screen-sm) {
        text-align: center;
      }

      &--office-text {
        font-size: $text;
        opacity: 0.9;
        margin-bottom: 0;
      }

      h3 {
        margin-top: 0;
      }
    }

    &__total {
      flex: 2;
      justify-content: flex-start;
      font-size: $heading;
      &__wrapper {
        text-align: center;
      }
      &--text {
        font-size: $text;
        display: block;
      }
    }

    &__dates {
      font-size: 1.25rem;
      margin-right: 1rem;
    }

    &__time {
      flex: 1;
      justify-content: flex-end;
      @media (max-width: $screen-sm) {
        display: flex;
        justify-content: center;
      }
      &__wrapper {
        height: 8rem;
        width: 8rem;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 100%;
        padding: 1.5rem;
        text-align: center;
        font-size: $text;
        &--number {
          font-size: $heading;
          display: block;
        }
        &--unit {
          display: block;
          font-size: 0.8rem;
        }
        &--completed {
          display: block;
          margin-top: 1.5rem;
        }
      }
    }

    i {
      color: #ffd700;
      font-size: 3rem;
      flex: 0.5;
      text-align: center;
      @media (max-width: $screen-sm) {
        font-size: 4rem;
      }
    }
  }

  a {
    &:hover {
      text-decoration: none;
      .competitions__header {
        box-shadow: 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.2);
      }
    }
  }
}
