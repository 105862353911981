@import "../partials/variables";

li.nav-item {
  + li.nav-item {
    margin-left: 0.5rem;
  }
  a {
    background-color: #fff;
    color: $brand-primary;
    font-size: 1.1rem;
    letter-spacing: 0.1px;
    border: none;

    .active {
      background-color: $brand-primary;
      color: #fff;
    }

    &:hover {
      background-color: $brand-primary;
      color: #fff;
    }
  }
}

.breadcrumb {
  background: #fff;
  border: thin solid #eee;
  padding: 1rem 1.5rem;
}
