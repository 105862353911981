// ************
//
// Adds different background on every other bar chart.
//
// @param $backgroundOdd - Takes normal and gradient backgrounds. Applies to every odd sibling
// @param $backgroundEven - Takes normal and gradient backgrounds. Applies to every even sibling
// @param $boxShadowColor - If not set, will not show.
//
// ************

@mixin barChart(
  $backgroundOdd,
  $backgroundEven,
  $backgroundBasic,
  $boxShadowColor: "rgba(0,0,0,0)"
) {
  li {
    &:nth-child(odd) {
      .redesign-list__body__bar {
        box-shadow: 0 0.4rem 1.6rem -0.2rem $boxShadowColor;
        background: $backgroundOdd;

        &__sub {
          box-shadow: none;
          background: $backgroundBasic;
        }
      }
    }
    &:nth-child(even) {
      .redesign-list__body__bar {
        box-shadow: 0 0.4rem 1.6rem -0.2rem $boxShadowColor;
        background: $backgroundEven;

        &__sub {
          box-shadow: none;
          background: $backgroundBasic;
        }
      }
    }
  }
}

// ************
//
// Multiline truncation
//
// @param $lineHeight
// @param $lineCount - Amount of lines in block
// @param $bgColor - background needs to be set for the truncation. (!) Does not work on transparent/over image.
//
// ************
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  margin-right: 0;
  padding-right: 1em;
  &:before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}
