.q-btn {
  border: 1px solid transparent !important;
  border-radius: 6px !important;
  letter-spacing: 0.75px;
  font-weight: 500;
  font-size: 1rem;
  padding: 8px 16px !important;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.q-btn--cancel {
  @extend .q-btn;
  border-color: rgb(var(--primary));

  &:hover {
    background-color: rgb(var(--primary)) !important;
    color: rgb(var(--white));
  }
}

.q-btn--remove {
  @extend .q-btn;
  border-color: rgb(var(--red));
  color: rgb(var(--red));

  &:hover {
    background-color: rgb(var(--red)) !important;
    color: rgb(var(--white));
  }
}

.q-btn--save {
  @extend .q-btn;
  background-color: rgb(var(--primary)) !important;
  color: rgb(var(--white));

  &:hover {
    background-color: rgba(var(--primary), var(--alpha-90)) !important;
  }
}

.q-btn--preview {
  @extend .q-btn;
  color: rgb(var(--blue)) !important;
  border-color: rgb(var(--blue)) !important;

  &:hover {
    background-color: rgb(var(--blue)) !important;
    color: rgb(var(--white)) !important;
  }
}

.q-btn--success {
  @extend .q-btn;
  background-color: rgb(var(--green)) !important;
  color: rgb(var(--white));

  &:hover {
    background-color: rgba(var(--green), var(--alpha-90)) !important;
  }
}

.q-btn--ignore {
  @extend .q-btn;
  background-color: rgb(var(--orange)) !important;
  color: rgb(var(--white));

  &:hover {
    background-color: rgba(var(--orange), var(--alpha-90)) !important;
  }
}
