@import "../../styles/partials/variables";
@import "./palette";
@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";

.ellipsify {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-card {
  border-radius: 3px;
  background-color: #fff;
  margin: 0.75rem;
  cursor: pointer;
  box-shadow: 0 3px 10px 0 #999;
  position: relative;
  color: $font-color-primary;
  @media (min-width: $screen-sm) {
    &:hover {
      background-color: $link-bg-color;
    }
    a:hover {
      transition: all 0.2s ease-out;
      color: $brand-primary;
    }
  }
  @media (min-width: $screen-md-min) {
    margin: 0.75rem 0;
  }
}

.search-card-details {
  display: flex;
  justify-content: flex-start;
  height: auto;
  padding: 1rem;
  flex-direction: row;
  @media (min-width: 1500px) {
    height: 12.25rem;
  }

  &__employee,
  &__object {
    @media (min-width: $screen-md-min) and (max-width: 1500px) {
      flex-direction: column;
    }
    @media (max-width: $screen-sm-max) {
      flex-direction: column;
    }
  }

  h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin: 1rem;
    @media (max-width: $screen-sm) {
      justify-content: flex-start;
    }
    @media (min-width: $screen-lg) {
      flex: 1;
    }
    @media (max-width: 1500px) {
      margin: 1rem 0 0 0;
    }

    &__contact,
    &__lead {
      display: none;
    }

    &__circle {
      height: 125px;
      width: 125px;
      @media (min-width: 1500px) {
        height: 75px;
        width: 75px;
      }
      min-width: 75px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      &--contacts {
        background: $contacts;
      }
      &--employee-image {
        border-radius: 100%;
        figure {
          height: 125px;
          width: 125px;
          @media (min-width: 1500px) {
            height: 75px;
            width: 75px;
          }
          border-radius: 100%;
          background-position: center top;
        }
      }
      &--employee {
        background: $employees;
      }
      &--leads {
        background: $leads;
      }
      &--object {
        background: $showings;
        height: 125px;
        min-width: 125px;
        margin: 0 auto;
      }

      i {
        font-size: 1.2rem;
        @media (min-width: 1500px) {
          font-size: 2rem;
        }
        color: $white;
      }
    }

    &__image {
      height: 125px;
      width: 125px;
      border-radius: 100%;
      align-self: center;
      figure {
        height: 125px;
        width: 125px;
        border-radius: 100%;
        background: $dark-grey no-repeat center center;
        background-size: cover;
      }
    }
  }

  &__person-contact-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0.5rem;
    width: 100%;
    @media (min-width: 1500px) {
      flex: 10;
      justify-content: center;
    }

    a,
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $night;
      margin: 0.2rem 0;
      i {
      }
    }

    a {
      transition: all 0.2s ease-out;
      &:hover {
        text-decoration: none;
        color: $quedro-blue;
      }
    }

    h4 i {
      &.fa-street-view {
        color: $leads;
      }
      &.icon-home {
        color: $showings;
      }
    }
  }
}
