@import "./palette";
@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";

.redesign-list {
  background: #fff;
  border-radius: 10px;
  margin: 1rem 0 1.5rem 0;
  font-family: "Roboto", sans-serif;
  box-shadow: 0 2rem 2rem -1.6rem rgba(0, 0, 0, 0.3);

  &__header {
    font-weight: bold;
    color: $night;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 1.5rem;
    background: $white;
    margin: 0 1rem;

    @media (max-width: $screen-sm) {
      font-size: 1rem;
    }

    i {
      padding-right: 0.5rem;
    }

    li {
      padding: 1rem 0;
      @media (max-width: $screen-sm) {
        padding: 0.3rem 0;
      }
    }

    &--mobile-icon {
      @media (max-width: $screen-sm) {
        flex: 2;
      }
    }
  }

  &__body {
    padding: 1rem 0;

    &__bar-wrapper {
      display: flex;
      align-items: center;
      color: $night;
      padding-right: 0.5rem;
    }

    &__bar {
      display: block;
      width: 0;
      margin-right: 1rem;
      transition: all 0.2s ease-out;
      border-radius: 10px;
      height: 1rem;
      padding-top: 0 !important;
      @media (max-width: $screen-sm) {
        margin-right: 0.5rem;
        display: inline-block;
      }
    }

    li {
      span {
        padding: 1rem 0;
      }
    }

    .icon-fire {
      color: #ffc370;
    }
  }

  hr {
    width: 100%;
    height: 5px;
    color: #d7d7d7;
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      align-items: center;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0.15rem 0;
        @media (max-width: $screen-sm) {
          text-align: left;
        }
      }
    }
  }
}

.old-q-table {
  background-color: white;
  padding: 2rem 2rem 6rem 2rem;
  box-shadow: 0 10px 15px 1px lightgray;
  margin-top: 1rem;
  margin-bottom: 1rem;
  overflow-x: auto;

  table {
    width: 100%;
    position: relative;

    tr:nth-child(odd) td {
      background-color: $off-white;
    }

    td,
    th {
      padding: 0.8rem;
      text-align: left;
      height: 5rem;
      min-width: 7.5rem;
      white-space: nowrap;
    }
  }
}
