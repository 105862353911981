@import "./palette";
@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";

.competition-daterangepicker {
  .week {
    span {
      color: $quedro-blue !important;
    }
  }

  span[class*="select-"] {
    background: $quedro-blue !important;
    color: #ffffff;
    box-shadow: 0 0.5rem 0.7rem 0.1rem rgba(0, 0, 0, 0.2);
  }
}

.datepicker-custom-today-class {
  background-color: rgba(var(--lightgray), var(--alpha-15));
  color: rgba(var(--blue));
}

bs-daterangepicker-container {
  .bs-datepicker-head {
    background: rgb(var(--blue));
  }

  @media (max-width: $screen-sm) {
    position: fixed !important;
    top: 0 !important;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    padding-top: 0.25rem;
    .bs-datepicker {
      margin: 0 2rem;
    }
  }
}
