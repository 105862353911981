.theme-quedro {
  margin: 4px 0;
  border-radius: 6px;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 0 0 0 #fff,
    0 0 0 1px rgba(0, 0, 0, 0.05), 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);

  .bs-datepicker-head {
    background-color: rgb(var(--secondary));
  }

  .bs-datepicker-body {
    border: none;

    .selected {
      background-color: rgb(var(--secondary)) !important;
    }
  }
}
