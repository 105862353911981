@import "./palette";

.sidebar {
  .tab {
    background-color: white;
    box-shadow: 1px 1px 4px #aaa;
    color: $brand-primary;
    border: none;
    padding: 1rem 1.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0.5rem;

    &.active,
    &.active:hover,
    &.active:focus,
    &:focus,
    &:hover {
      background: $sky-blue;
      color: white;
      text-decoration: none;
    }
  }
}
