$icon-font-path: "../../fonts/bootstrap/";

// Bootstrap overrides
$font-family-base: "Open Sans", sans-serif;
$font-size-base: 16px;
$font-size-large: $font-size-base;
$brand-primary: #0180ff;
$nav-link-padding: 15px 30px;
$nav-tabs-active-link-hover-bg: $brand-primary;
$nav-tabs-active-link-hover-color: #fff;
$border-radius-base: 3px;
$border-radius-large: 3px;
$border-radius-small: 3px;
$input-border: #ddd;

// Colors
$navbar-bg-color: #050b1f;
$contacts: rgba(var(--blue));
$company: rgba(var(--green));
$leads: #71c57a;
$campaigns: #5362d6;
$salesmeeting: #f6a623;
$employees: #6489b3;
$showings: #d3437f;
$stars: #c69257;
$font-color-primary: #444;
$font-color-meta: #999;
$link-bg-color: #dfeaf6;
$background-color-primary: #edf0f7;
$list-header-background-color: #eaf0f6;

$table-header-bg-color: $list-header-background-color;
$table-bg-color: #fff;
$table-border-color: #ddd;

$resource-header-bg-color: $list-header-background-color;
$resource-bg-color: #fff;
$resource-border-color: #ddd;

$search-list-header-bg-color: #e3e3e3;
$search-list-border-color: #e3e3e3;

// Input colors
$input-icon-bg: #5881c4;
$input-icon-color: #fff;

// Layout colors
$layout-background-color: #3a4e75;

// Styles
$font-size-meta: 14px;
$navbar-border-left-width: 0.4rem;

// Disabled colors
$disabled-color: #eee;

// Z-index - highest presidency is the global modal which lies on z-index 1050 so nothing should be above it.

$search-overlay-z-index: 1045;
$standalone-sidebar-z-index: 1040;
$sidebar-z-index: 1035;
$spinner-z-index: 100;
$sidebar-z-index-footer: 4;
$sidebar-z-index-mobile-tabs: $sidebar-z-index-footer;

// HD screen / wide desktop
$screen-xl-min: 2000px !default;
