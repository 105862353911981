@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "./palette";
@import "../partials/variables";

.btn {
  border: 0;
  border-radius: 10px;
  color: #000000;
  background: #fff;
  font-family: Roboto, sans-serif !important;
  letter-spacing: 0.75px;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.8rem;
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  @media (min-width: $screen-sm-min) {
    width: auto;
  }

  i {
    + span {
      padding-left: 0.25rem;
    }
  }

  &:active {
    transform: scale(0.9);
    transition: all 0.2s linear;
  }

  &:disabled {
    box-shadow: none;
    background: #cccccc !important;
    cursor: not-allowed;
  }

  &:focus {
    outline: 0;
  }

  &-sm {
    padding: 0.65rem;
    font-size: 0.9rem;
  }

  &-xs {
    padding: 0.5rem;
    font-size: 0.85rem;
  }

  &-disabled {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #cccccc !important;
    cursor: not-allowed;
  }

  &-primary {
    background: $ocean-blue;
    color: $white;
    box-shadow: 0 2rem 2rem -1.6rem rgba(0, 0, 0, 0.4);
    transition: background 1s ease-out;

    &:hover,
    &:active,
    &:focus {
      background: $ocean-blue-hover;
    }
  }

  &-secondary {
    color: $white !important;
    transition: background 0.2s ease-out;
    background: $quedro-blue;
    background: $sky-blue;
    background-size: 300%;
    background-position: left;
    border: 0;

    &:hover,
    &:focus,
    &:active {
      color: $white;
      background: $quedro-blue;
      background: $sky-blue;
      background-size: 100%;
      background-position: left;
    }

    &--ghost {
      color: $quedro-blue !important;
      border: 1.5px solid $quedro-blue;
      background: none;

      &:hover,
      &:focus {
        color: $white !important;
        background: $quedro-blue;
        font-weight: 600;
      }

      &:active {
        border: 1.5px solid transparent;
        color: $white !important;
        background: $quedro-blue;
      }
    }

    i {
      font-size: 1.2rem;
    }
  }

  &-cancel {
    border: 1.5px solid $light-grey;
    background: none;
    color: #333;
    text-decoration: none;

    &:hover {
      @media (min-width: $screen-xs) {
        background: $light-grey;
        color: $white;
        text-decoration: none !important;
      }
    }

    &--filled {
      transition: all 0.2s ease-out;
      background: $night;
      color: $white;

      &:active {
        background: #000;
      }
    }
  }

  &-success {
    color: $white;
    transition: background 0.2s ease-out;
    background: $green;
    background: $grass;
    background-size: 300%;
    background-position: left;
    border: 0;

    &:hover,
    &:focus,
    &:active {
      background: $green;
      background: $grass;
      background-size: 100%;
      background-position: left;
    }

    &--ghost {
      color: $green;
      border: 1.5px solid #28e57c;
      background: none;

      &:hover,
      &:focus {
        border: 1.5px solid transparent;
        color: $white;
        background: $green;
        background: $grass;
        background-size: 130%;
        background-position: center;
      }

      &:active {
        transform: scale(0.9);
      }
    }
  }

  &-info {
    color: $white;
    background: $night;
    transition: all 0.2s ease-out;

    &:hover,
    &:focus {
      background: #000;
    }

    &--ghost {
      background: transparent;
      color: $night;
      border: 1.5px solid #333;
      transition: all 0.2s ease-out;

      &:hover,
      &:focus {
        color: $white;
        background: $night;
      }
    }
  }

  &-link {
    color: $quedro-blue;
    background: none;
    transition: all 0.2s ease-out;

    &:hover,
    &:focus {
      text-decoration: none;
      background: #e9ecef;
    }
  }

  &-alt-1 {
    color: $white;
    transition: background 0.2s ease-out;
    background: $forest;
    background-size: 100%;
    background-position: left;
    border: 0;

    &:hover,
    &:focus,
    &:active {
      color: $white;
      background: $forest;
      background-size: 150%;
    }
  }

  &-warning {
    background: $orange;
    color: $white;
    box-shadow: 0 2rem 2rem -1.6rem rgba(0, 0, 0, 0.4);
  }

  &-danger {
    transition: all 0.2s ease-out;
    color: $alert;
    background: rgba(250, 0, 0, 0);

    &:hover,
    &:focus,
    &:active {
      color: $white;
      background: $alert;
      background: $blood;
      background-size: 100%;
      background-position: left;
    }

    &--ghost {
      color: $alert;
      border: 1.5px solid $alert;
      background: none;

      &:hover,
      &:focus {
        border: 1.5px solid transparent;
        color: $white;
        background: $alert;
        background-size: 130%;
        background-position: center;
      }

      &:active {
        transform: scale(0.9);
      }
    }

    &-alt-1 {
      transition: all 0.2s ease-out;
      color: $white;
      background: $alert;
      background: $blood;
      background-size: 100%;
      background-position: left;

      &:hover,
      &:focus,
      &:active {
        color: $white;
        background: $blood;
        background-size: 150%;
      }
    }
  }

  &-circle {
    border-radius: 50%;
  }

  &-create {
    padding-top: 0.63rem;
  }

  &-ghost {
    color: $white;
    text-align: left;
    margin: 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    background: none;
    border-radius: 0;
    letter-spacing: 2px;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  &-default.active {
    background-color: $quedro-blue !important;
  }

  &-close {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: none;
    font-size: 1.25rem;
    padding: 1rem;
    height: 50px;
    width: 50px;
    transition: all 0.2s ease-out;
    border-radius: 100%;
    background: transparent;

    &:active {
      background: #ccc;
      color: $alert;
      transform: scale(0.9);
    }

    @media (min-width: $screen-xs) {
      &:hover {
        background: #ccc;
        color: $alert;
      }
    }

    &--sm {
      height: 35px;
      width: 35px;
    }
  }

  &-group {
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: stretch;

    &:hover {
      cursor: pointer;
    }

    label {
      background: $white;
      color: #666666;
      transition: all 0.3s ease-in;

      &:hover {
        color: $btn-primary-color;
        background: $quedro-blue;
      }
    }

    button,
    .btn {
      flex: 1;
      border: 0;
      height: 43px !important;

      &:first-of-type {
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
      }

      &:last-of-type {
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
      }
    }

    .active {
      background: $quedro-blue !important;
      border-color: $quedro-blue !important;
    }

    input[type="radio"] {
      display: none;

      &:checked + label {
        color: $btn-primary-color;
        background: $sky-blue;
      }

      & + label.btn {
        text-overflow: ellipsis;
        font-style: normal;
        width: calc(100% / 3);
      }
    }
  }
}
