@import "./_variables.scss";
@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";

@media (min-width: $screen-md-min) {
  .modal-dialog {
    width: 900px;
  }
  .mapmodal {
    width: 90%;
  }
}

@media (min-width: $screen-lg-min) {
  .modal-dialog {
    width: 1100px;
  }

  .modal-sm {
    width: 600px;
  }
}
