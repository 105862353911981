@import "../partials/variables";
@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";

.input-group-addon {
  padding: 6px 18px;
  border-color: $input-icon-bg;
}

.input-group-addon.icon {
  background-color: $input-icon-bg;
  color: $input-icon-color;
  font-size: 1.2rem;
}

.input-group-addon + select {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;

  &.input-lg {
    line-height: $line-height-large;
  }
}
